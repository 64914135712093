import React, { Component } from 'react';
import '../CSS/navigation.css';


class Navigation extends Component {

  render() {
      let visibility = "hide";

      if (this.props.menuVisibility) {
          visibility = "show";
      }

      return (
        <div id={visibility} className='navigation'>
                {this.props.data.map((item,i) => {
                    return (
                            <div className="item" key={item.id} onClick={this.props.changeData.bind(this,i)} >
                                <img src={item.profielfoto} alt=""/>
                                <div>
                                    <h2>{item.voornaam}</h2>
                                    <h2>{item.achternaam}</h2>
                                </div>
                            </div>
                    )
                })}
        </div>
    );
  }
}

export default Navigation;
