import React, { Component } from 'react';
import '../CSS/start.css';
import video from './graf_video_bis.mp4';


class Start extends Component {
    render() {
        let visibility = "";

        if (this.props.slide) {
            visibility = "up";
        }


        const dataLoaded = this.props.dead;
        let go;

        if (dataLoaded.length >0){
            go = <div className="cta" onClick={this.props.clickDown}>
                Naar het overzicht
            </div>
        } else{
            go = <div className="cta" id="wait">
                Even wachten
            </div>
        }
        return (
            <div className='start' id={visibility}>
                <div id="contain">
                    <div>
                        <h1>
                            Het kerkhof van de onvervangbaren
                        </h1>
                        <p>
                            Het is een boutade, vaak geciteerd door wijlen Steve Stevaert: “Het kerkhof ligt vol onvervangbare mensen.” VRTNWS ging - ter ere van Allerheiligen en Allerzielen - op zoek naar hun laatste rustplaats, de onsterfelijken uit de Belgische geschiedenis van wie je het graf in Vlaanderen of Brussel nog altijd kunt bezoeken. Mogen ze rusten in vrede.
                        </p>
                    </div>

                    {go}
                    <a href="https://www.vrt.be/vrtnws/nl/" target='_blank' rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.43 76.07">
                            <path className="cls-1"
                                  d="M97.92,23.44h-.21V73a1,1,0,0,1-1,1H84.84a1,1,0,0,1-1-1V3.06a1,1,0,0,1,1-1h15.78a1,1,0,0,1,.88.5l28.18,49.35h.21V3.06a1,1,0,0,1,1-1h11.86a1,1,0,0,1,1,1V73a1,1,0,0,1-1,1H127.24a1,1,0,0,1-.88-.52Z"/>
                            <path className="cls-1"
                                  d="M284.7,22.31a1,1,0,0,1-1-.93c-.72-6.19-6.73-9.56-15.46-9.56-7.09,0-13.88,2.68-13.88,8.74,0,18.61,46.06-2.16,46.06,32.9,0,0,0,22.61-31.46,22.61-28.14,0-30.35-18.43-30.6-24.46a1,1,0,0,1,1-1H252.3a1,1,0,0,1,1,1c.76,10,8.73,12.72,16.61,12.72s15.62-3,15.62-10.49c0-19-46,.93-46-32.79,0-10.28,9.66-21,29.81-21,19.36,0,28.64,9.68,29.26,21.25a1,1,0,0,1-1,1.06Z"/>
                            <path className="cls-1"
                                  d="M37,2.06a37,37,0,1,0,37,37v-37ZM30.67,30,24.32,48.43a.5.5,0,0,1-.48.35H19a.5.5,0,0,1-.48-.35L12.12,30a.51.51,0,0,1,.48-.66h4.25a.5.5,0,0,1,.48.36L21.39,42.4h.08l4-12.72a.52.52,0,0,1,.49-.36h4.24A.51.51,0,0,1,30.67,30Zm15.2,3.15a.5.5,0,0,1-.52.5h-.2c-4-.18-6.72,3-6.86,7V47h0v1.29a.5.5,0,0,1-.51.5H33.73a.5.5,0,0,1-.5-.5V29.82a.5.5,0,0,1,.5-.5H37.6a.5.5,0,0,1,.5.5V32c2.14-2.62,4.83-3,7.3-2.81a.49.49,0,0,1,.47.5Zm9.84,15.95c-4.3,0-7.31-3.15-7.31-7.85V24.46a.45.45,0,0,1,.45-.46H53a.45.45,0,0,1,.45.45v4.87h6.4a.46.46,0,0,1,.46.45v3.4a.46.46,0,0,1-.46.46h-6.4s0,7.5,0,7.75v.19c0,1.68,1,2.72,2.32,2.72a2.32,2.32,0,0,0,2.31-2.42v0a.65.65,0,0,1,.67-.63h3.59a.45.45,0,0,1,.45.44v.1A7.14,7.14,0,0,1,55.71,49.08Z"/>
                            <path className="cls-1"
                                  d="M239.74,2.06h-12.1a1,1,0,0,0-1,.78L214.6,57.46l-11-54.6a1,1,0,0,0-1-.8H184.89a1,1,0,0,0-1,.8l-11,54.6L160.8,2.84a1,1,0,0,0-1-.78h-12.1a1,1,0,0,0-1,1.23l16.59,69.95a1,1,0,0,0,1,.78h17.3a1,1,0,0,0,1-.81l11.14-54.4,11.14,54.4a1,1,0,0,0,1,.81h17.3a1,1,0,0,0,1-.78L240.72,3.29A1,1,0,0,0,239.74,2.06Z"/>
                        </svg>
                    </a>
                </div>
                <video
                    autoPlay
                    muted
                    loop
                >
                    <source src={video} type="video/mp4" />
                </video>

            </div>
        );
    }
}

export default Start;
