import React, { Component } from 'react';
import Navigation from './Components/navigation';
import MenuButton from './Components/menubutton';
import Footer from "./Components/footer";
import Start from "./Components/start";
import Content from './Components/content';
import ResponsiveDialog from "./Components/dialog";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import {breakLine} from "./utils/util.js";

import './CSS/main.css';


class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            visible:true,
            slide:false,
            dead:[],
            grave: '',
            nav:'',
            open: false
        };
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.toggleMenu =  this.toggleMenu.bind(this);
        this.changeData =  this.changeData.bind(this);
        this.nextGrave = this.nextGrave.bind(this);
        this.previousGrave = this.previousGrave.bind(this);
        this.clickDown = this.clickDown.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);

    }

    handleClickOpen() {
        this.setState({
            open: true
        });
    };

    handleClose () {
        this.setState({
            open: false
        });
    };

    componentDidMount() {
        const itemsRef = firebase.database().ref('grafzerken').orderByChild('voornaam');
        itemsRef.on('value', (snapshot) => {
            let items = snapshot.val();
            let newState = [];
            for (let item in items) {
                newState.push({
                    id: item,
                    voornaam: items[item].voornaam,
                    achternaam: items[item].achternaam,
                    plaats: items[item].plaats,
                    profielfoto: items[item].foto,
                    zerk_a: items[item].zerk_a,
                    zerk_b: items[item].zerk_b,
                    tekst: breakLine(items[item].tekst),
                    geboren: items[item].geboren,
                    overlijden: items[item].overlijden
                });
            }
            newState.sort(this._sortAlpha("achternaam"))
            this.setState({
                dead:  newState,
                nav: ''
            });
        });
    }


    _sortAlpha = (property) => {
        var sortOrder = 1;

        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a,b) {
            if(sortOrder === -1){
                return b[property].localeCompare(a[property]);
            }else{
                return a[property].localeCompare(b[property]);
            }
        }
    }


    changeData(e){
        this.setState({
            grave: this.state.dead[e],
            visible:false,
            nav: e
        });
        window.scrollTo(0, 0);
    }

    handleMouseDown(e){
        this.toggleMenu();
        e.stopPropagation();
    }

    toggleMenu() {
        this.setState ({
            visible: !this.state.visible
        })
    }

    clickDown(){
        this.setState ({
            slide: true,
            grave: this.state.dead[0]
        })
    }

    nextGrave() {
        if (this.state.nav + 1 >= this.state.dead.length) {
            this.setState({
                nav: 0,
                grave: this.state.dead[0]
            });
        }
        else {
            this.setState({
                nav: this.state.nav + 1,
                grave: this.state.dead[(this.state.nav + 1)]
            });
        }
        window.scrollTo(0, 0);
    }

    previousGrave(){
        if (this.state.nav > 0) {
            this.setState({
                nav: this.state.nav - 1,
                grave: this.state.dead[this.state.nav - 1]
            });
        }
        else {
            this.setState({
                nav: this.state.dead.length - 1,
                grave: this.state.dead[this.state.dead.length - 1]
            });
        }
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className='body' >
                <ResponsiveDialog
                    open={this.state.open}
                    clickClose={this.handleClose}
                />
                <Start
                    dead={this.state.dead}
                    clickDown={this.clickDown}
                    slide={this.state.slide}
                />
                <MenuButton
                    handleMouseDown={this.handleMouseDown}
                    menuVisibility={this.state.visible}
                />
                <div className="content-container">
                    <Navigation
                        changeData = {this.changeData}
                        data={this.state.dead}
                        menuVisibility={this.state.visible}
                    />
                    <Content
                        data={this.state.grave}
                        nextGrave = {this.nextGrave}
                        prevGrave = {this.previousGrave}
                    />
                    <Footer
                        onClick={this.handleClickOpen}
                    />
                </div>
            </div>
        );
    }
}

export default App;
