import React, { Component } from 'react';
import '../CSS/menubutton.css';


class MenuButton extends Component {
    render() {
        return (
            <div className='openbutton'>
                <svg id={this.props.menuVisibility ? 'invisible' : 'visible'} onClick={this.props.handleMouseDown} className="menu" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                    <path  fill="#818b9d" className="cls-1"
                           d="M500,1000h0C223.86,1000,0,776.14,0,500H0C0,223.86,223.86,0,500,0h500V500C1000,776.14,776.14,1000,500,1000Z"/>
                    <rect fill="#f1f2f4" className="cls-2" x="175" y="338" width="649" height="55" rx="27.5"/>
                    <rect fill="#f1f2f4" className="cls-2" x="175" y="473" width="649" height="55" rx="27.5"/>
                    <rect fill="#f1f2f4" className="cls-2" x="175" y="600" width="649" height="55" rx="27.5"/>
                </svg>
                <svg id={this.props.menuVisibility ? 'visible' : 'invisible'} onClick={this.props.handleMouseDown} className="menu"  data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                    <path fill="#818b9d" className="cls-1"
                          d="M500,1000h0C223.86,1000,0,776.14,0,500H0C0,223.86,223.86,0,500,0h500V500C1000,776.14,776.14,1000,500,1000Z"/>
                    <path fill="#F1F2F4" className="cls-2"
                          d="M537.89,500,729,308.93A27.5,27.5,0,0,0,729,270h0a27.5,27.5,0,0,0-38.89,0L499,461.11,308.93,271A27.5,27.5,0,0,0,270,271h0a27.5,27.5,0,0,0,0,38.89L460.11,500,270,690.07A27.5,27.5,0,0,0,270,729h0a27.5,27.5,0,0,0,38.89,0L499,538.89,690.07,730A27.5,27.5,0,0,0,729,730h0a27.5,27.5,0,0,0,0-38.89Z"/>
                </svg>
            </div>

        );
    }
}

export default MenuButton;


