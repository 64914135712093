import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './main';
import firebase from 'firebase/app';
import * as serviceWorker from './serviceWorker';

const config = {
    apiKey: "AIzaSyA8p0xYVlP8PIz0KK-2hab1MC9fKJuChiA",
    authDomain: "api-project-7429617504.firebaseapp.com",
    databaseURL: "https://api-project-7429617504.firebaseio.com",
    projectId: "api-project-7429617504",
    storageBucket: "api-project-7429617504.appspot.com",
    messagingSenderId: "7429617504"
};

firebase.initializeApp(config);


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
