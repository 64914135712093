import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { FaTimesCircle} from 'react-icons/fa';
import logo from '../Logo_VRTnws.svg';


import '../CSS/dialog.css';

class ResponsiveDialog extends Component {

    render() {
        const { fullScreen } = this.props;

        return (
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.open}
                    aria-labelledby="responsive-dialog-title"
                    id="dialog"
                >
                    <DialogTitle id="responsive-dialog-title">{"Credits"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <b>Foto's:</b> Alexander Dumarey <br />
                            <b>Design:</b>  Alexander Dumarey & Robbe De Graeve <br />
                            <b>Ontwikkeling:</b> Robbe De Graeve <br />
                            <b>Tekst:</b> Alexander Verstraete & Lucas Vanclooster <br />
                            <br />
                            <a href="https://www.vrt.be/vrtnws/nl/#/11/1/44021/percentages" target="_blank" rel="noopener noreferrer"><img src={logo} alt="" height="20"/></a>
                            <br />
                            <FaTimesCircle color={'#16284A'} onClick={this.props.clickClose} id="close"/>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

ResponsiveDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ResponsiveDialog);