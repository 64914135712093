import React, { Component } from 'react';
import '../CSS/content.css';
import { FaMapMarkerAlt,
    FaCross,
    FaAngleRight,
    FaAngleLeft
} from 'react-icons/fa';


class Content extends Component {
    componentDidUpdate(){
        this._div.scrollTop = 0;
    }
  render() {
      return (
        <div className='content'>
                    <div className="container" id={this.props.data.id} key={this.props.nav}>
                        <div className="tekst">
                            <div className="bio">
                                <img src={this.props.data.profielfoto} alt=""/>
                                <div>
                                    <br/>
                                    <h2>{this.props.data.voornaam} </h2>
                                    <h2>{this.props.data.achternaam}</h2>
                                    <br/>
                                    <p>°  {this.props.data.geboren} </p>
                                    <p><FaCross/>  {this.props.data.overlijden}</p>
                                    <br />
                                    <p><FaMapMarkerAlt />  {this.props.data.plaats}</p>
                                </div>
                            </div>
                            <div className="text"  ref={(ref) => this._div = ref}>
                                <p>{this.props.data.tekst}</p>
                            </div>
                        </div>
                        <div className="images">
                            <img src={this.props.data.zerk_a} alt=""/>
                            <img src={this.props.data.zerk_b} alt=""/>
                        </div>
                    </div>
                        <div className="navi">
                            <div className="prev" onClick={this.props.prevGrave}>
                                <FaAngleLeft color={'#16284A'}/>
                            </div>
                            <div className="next" onClick={this.props.nextGrave}>
                                <FaAngleRight color={'#16284A'} />
                            </div>
                        </div>
                    </div>
    );
  }
}

export default Content;
