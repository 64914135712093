import React  from 'react';

export const breakLine = (text) => {
    const br  = React.createElement("br");
    const regex = /(<br \/>)/g;
    return text.split(regex).map(function(line, index){
    return line.match(regex) ? <br key ={"key_"+ index} /> : line;
    });
}

export default { breakLine};



